$enable-shadows: true;
$enable-responsive-font-sizes: true;
$grid-gutter-width: 25px !default;

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 960px,
  xl: 1024px,
  xxl: 1440px
);

$container-max-widths: (
  sm: 490px,
  md: 790px,
  lg: 950px,
  xl: 1280px,
  xxl: 1400px
);

$theme-colors: (
  primary: #0033cc,
  secondary: #dddddd,
  success: #73f3c3,
  info: #fdf3b5,
  warning:  #ff9379,
  danger: #FD3100,
  light: #ffffff,
  dark: #343a40
);

$color-red-dark: rgb(121,0,0);
$color-red-light: rgb(187,0,0);
$color-red: #FD3100;
$color-mid-grey: rgb(51,51,51);
$color-light-grey: rgb(136, 136, 136);
$color-header-grey:rgba(232,232,232,1);
$color-text: rgba(0,0,0,1);
$color-text-dark: rgba(68,68,68,1);
$color-bg-nav: rgba(0,0,255,1);

/* Typography */
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: Plex, Arial, sans-serif;
$font-family-redaktion: hkgrotesk, Arial, sans-serif;
$line-height-base: 1.3;
$text-color: rgb(102,102,102);

/* Buttons */
$btn-default-color: $text-color;
$btn-font-weight: 500;

/* Inputs */
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten(map-get($theme-colors, 'primary'), 25%);
$input-color-placeholder: lighten($text-color, 30%);

/* Spacers */
$spacer: 1px;
$spacers: (0:0);
@for $i from 1 through 150 {
  $spacers: map-merge($spacers, (
  $i: ($i * $spacer)
  ));
}

/* Panels */
$panel-default-heading-bg: #fff;

/* Breadcrumb */
$breadcrumb-active-color: $text-color;
$breadcrumb-bg: transparent;

/* Alerts */
$alert-padding: 10px;

/* Modal */
$modal-lg: 700px;