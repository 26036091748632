html, body     { 
  font-weight: 400; 
  height: 100vh; 
  width: 100%; 
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
  color: #fff;
  background: $color-bg-nav;
}

::selection {
  color: #fff;
  background: $color-bg-nav;
}

a {
  outline: none !important;
}

strong, .strong {
  font-weight: 500;
}

p { 
  font-size: $base-font-size;
  line-height: 1.4;
  margin-bottom: 1rem !important;
}

.small-text {
  font-size: $small-font-size;
}

hr {
  margin-top:10px;
  margin-bottom:14px;
}

u, .underline {
  text-decoration: none;
  border-bottom: 1px dotted #000;
}

.btn {
  border-radius: 2px;
  padding: 4px 20px;

  &.btn-outline-light:hover {
    color: $color-text !important;
  }
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
  font-weight: 400;
  color: $color-mid-grey;
}

h1, .h1 {
  font-size: $h1-font-size;
  line-height: 1.2;

  small {
    font-size: 17px;
    letter-spacing: 0;
    color: $color-text;
    display: block;
    margin-bottom: 15px;
  }
}

.h1:empty, .h2:empty {
  display: none;
}

h1 + p {
  margin-top: 40px;
}

h2, .h2 {
  font-size: $h2-font-size;
  line-height: 1.25;
}

h2 {
  font-size: $base-font-size;
  padding-top: 5rem;

  
  &.ankertitel {
    font-size: $h3-font-size;
    margin-bottom: 0;
    padding-top: 0;
  }

  &.ankerlinie {
    border-top: 2px solid #000;
    padding: 5rem 0 0;
  }
}

h3, .h3 {
  font-size: $h3-font-size;
  line-height: 1.3;

  .small {
    line-height: 1.2;
  }
}

h3 ~ p.small-text {
  margin-top: .75rem;
}

h4, .h4 {
  font-size: $h4-font-size;
  line-height: 1.4;
}

h5, .h5 {
  font-size: $h5-font-size;
  line-height: 1.4;
}

figcaption {
  margin: .5rem 0 0;
  font-size: .875rem;
}

.bg-grey {
  background-color: $bg-grey;
}

.bg-bluegrey {
  background-color: $bg-bluegrey !important;
}

.bg-rose {
  background-color: $bg-rose;
}

.bg-elder {
  background-color: $bg-elder;
}

.dot {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #333;
  margin: .25rem;

  &.dot-xl {
    width: 2rem;
    height: 2rem;    
  }
}

h3 {
  .dot {
    display: inline-block;
  }

  &.linie-oben {
    border-top: 2px solid #888;
    padding-top: 1rem;
  }
}

.erw {
  background-color: $color-erw;
}

.senior {
  background-color: $color-senior;
}

.fam {
  background-color: $color-fam; 
}

.kind {
  background-color: $color-kind;
}

.schule {
  background-color: $color-schule;
}

.handicap {
  background-color: #92ACAD;
}

.durchgestrichen { text-decoration: line-through; }

#no_result, #no_result_formate { height:10px; }
#no_result_formate div { display:none; }

span.fehler { margin-bottom:9px; }
span.fehler:last-of-type { margin-bottom:0; }
span.success { display:block; color:green; }

.btn {
  border-radius: 0;
  padding: .2rem .5rem;
  display: inline-flex;
  align-items: center;

  &.btn-outline-primary, 
  &.btn-outline-secondary, 
  &.btn-outline-danger,
  &.btn-outline-dark {
    border-width: 2px;

    i {
      font-size: 150%;
    }
  }

  &.more, &.bestellung {
    margin-bottom: .25rem;

    i {
      font-size: 1.5;
      margin-left: 3rem;
      padding: 0;
      line-height: 1;
    }
  }

  &.ico {
    margin-bottom: .25rem;
    display: inline-flex;
    align-items: center;

    i {
      font-size: 1.075rem;
      padding: 0;
      line-height: 1;
      margin-right: .35rem;
    }
  }
}

.more-p {
  text-align: center;
  margin: .5rem 0;

  .more-btn {
    display: inline-flex;
    border: 1px solid #333;
    font-size: 1rem;
    padding: .125rem .75rem;
    border-radius: 50rem !important;
  
    &:before {
      font-family: Phosphor;
      content: "\e99b";
      margin-right: .25rem;
    }

    &:hover {
      background-color: #fff;
    }
  }
}

.truncate, 
.textbox h3,
.textbox p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.textbox p, .textbox .h5 {
  font-size: 80%;
}

.textbox .h3 {
  font-size: 200%;
  line-height: 1.2;
  hyphens:auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  @media only screen and (min-width:960px) and (max-width: 1219px) {
    font-size: 150%;
  }
}

.toggle-txt { display:none; }

.vorschau {
  overflow-x: hidden;
  .refresh { margin:0 12px 0 0; cursor:pointer; float:right; font-size:24px; color:#d00; }
  .refresh:hover { color:#61D1AA; }
  .container { padding-left:10px; padding-right:10px; }
  .slider.hero-slider .slide { height:25rem; width:100%; }
}
.modul_box { color:#9FBCBE; width:100%; text-align: center; font-size:14px; 
             padding:20px; margin:0; background-color: #f9f9f9; color:#89A3A6;
             position: relative; border:1px solid #e6e6e6; border-radius: .25rem }

.img-publikation {
  background-size: 80% auto;
  background-position: bottom left;
  background-repeat: no-repeat;
}

body { 
  font-size: $base-font-size; 
  display: flex; 
  min-height: 100vh; 
  flex-direction: column; 
  overflow-x: hidden;

  /* ------------ Desktop/mobile styles ------------ */
  header, aside#tablet-nav {
    a {
      color: $color-text;
      text-decoration: none;

      &:hover, &.current {
        color: $color-text;
        text-decoration: none;
      }
    }

    ul {      
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        float:left;
        margin: 0 10px 0;
      }
    }
  }

  &.ankernavi {
    .content-section {
      &:first-of-type {
        padding-top: 5rem;
      }
    }
  }

  /* ------------ Header ------------ */
  &.alert_msg header #anchor-nav {
    margin-top: 8.5rem;
  }

  header {
    z-index: 12;
    position: relative;

    #anchor-nav {
      position: absolute;
      z-index: 1000;
      right: 0;
      left: 0;
      top: 0;
      margin-top: 6rem;

      .nav-link {
        padding: 0;
        margin: 0 .5rem;
        text-shadow: #e0e0e0 1px 1px 0;

        &.active, &:hover {
          border-bottom: 1px solid #000;
        }
      }
    }

    #header-wrap {
      padding: 0;
      position: fixed;
      z-index: 1000;
      right: 0;
      left: 0;
      top: 0;

      &.headroom {
        will-change: transform;
        transition: transform 200ms linear;
      }
      &.headroom--pinned {
        transform: translateY(0%);
      }
      &.headroom--unpinned {
        transform: translateY(-100%);
      }
      &.headroom--not-top {
        box-shadow: 0px 5px 15px 5px rgba(0,0,0,0.07);
      }

      .white-bg {
        background-color: #fff;
        height: 5rem;
        padding-top: .75rem;
        padding-bottom: .75rem;
        width: 100%;
      }
    }

    #logo {
      border-bottom: 2px solid #000;
      color: $color-text !important;
      margin: -.75rem 0 0 1.75rem;
      padding-bottom: .375rem;
    }

    @media only screen and (max-width:479px) {
      #logo {
        margin-left: 1rem;
      }
    }
      
    .breadcrumb, .menu-text, #logo {
      font-size: 2.25rem;
      line-height: 1;
    }

    .breadcrumb {
      color: #ccc !important;
      margin: -.75rem 0 0;
      padding: 1.25rem 1rem 0;

      a, span {
        color: #ccc !important;
        line-height: 1;
        padding-bottom: .375rem;
        border-bottom: 2px solid transparent;
        display: inline-block;

        &:hover, &.current {
          border-bottom: 2px solid #ccc;
          padding-bottom: .375rem;
        }
        &.current {
          display:none;
        }
      }
      span {
          border-bottom: 2px solid #ccc;
          padding-bottom: .375rem;
      }
      span.slash {
        border:0;
      }
      span.l2, span.slash {
        display:none;
      }


    }

    .menuwrap {
      height: 100%;
      margin-right: 1.75rem;

      #hamburger {
        margin-top: -6px;

        .menu-text {
          display: block;
          padding-right: 15px;
          margin: -7px 0 0;
        }
        
        .menu-icon {
          span {
            display: block;
            background: #000;
            width: 38px;
            height: 3px;
            margin-bottom: 8px;
            -webkit-transition: all .25s linear;
            -o-transition: all .25s linear;
            transition: all .25s linear;
          }
        }
      }
    }
  }

  #top-banner {
    position: relative;
    z-index: 11;
    padding: .5rem 1rem .5rem 1.75rem;
    background-color: #FFF6C7;

    a, a:hover, a:visited {
      color: $color-text;
      text-decoration: none;

      &.btn {
        color: #FD3100;

        &:hover {
          color: #fff;
        }
      }
    }
    #top-banner-button {
      &::after {
        padding-left: .5rem;
        font-family: Phosphor;
        content: '\ea5e';
      }

      &.collapsed {
        &::after {
          padding-left: .5rem;
          content: '\e99b';
        }
      }
    }
  }

  @media only screen and (max-width:479px) {
    #top-banner {
      padding: .5rem 1rem;
    }
  }

  .modal {
    .modal-dialog {
      max-width: 1480px;
      margin: 1.75rem auto;
      padding: 1rem;
  
      .modal-content {
        border: 0;
        border-radius: 0;
        background-color: #fff6c7;
  
        .modal-header {
          border-bottom: 0;
          padding: 1rem 1rem 0;
  
          button.close {
            font-size: 2rem;
          }
        }
        .modal-body {
          padding: 0 1rem 2.5rem;

          a:not(.btn) {
            border-bottom: 1px solid #333;
            color: #333;
      
            &.text-danger {
              color: $color-red;
              border-bottom: 1px solid $color-red;
            }
      
            &:hover {
              text-decoration: none;
              border-bottom: 1px solid #000;
              color: #000;
            }
          }

          img {
            margin-top: .5rem;
          }
        }
      }
    }
    .modal-dialog.klein {
      max-width:800px;
    }
  }

  /* ------------ Mobile menu ------------ */
  aside#tablet-nav {
    position: fixed;
    z-index: 60;
    top: 0;
    right: 0;
    height: calc(100vh - 2rem);
    width: 500px;
    padding: 2rem 1rem 2rem 2rem;
    margin: 1rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: translate3d(600px,0,0);
    transform: translate3d(600px,0,0);
    -webkit-transition: -webkit-transform .7s cubic-bezier(.645,.045,.355,1);
    transition: -webkit-transform .7s cubic-bezier(.645,.045,.355,1);
    -o-transition: transform .7s cubic-bezier(.645,.045,.355,1);
    transition: transform .7s cubic-bezier(.645,.045,.355,1);
    transition: transform .7s cubic-bezier(.645,.045,.355,1),-webkit-transform .7s cubic-bezier(.645,.045,.355,1);
    z-index: 100001;
    background-color: $color-bg-nav;
    overflow-y: auto;
    overflow-x: hidden;
    display:none;

    &.open {
      -webkit-transform: translateZ(0) !important;
      transform: translateZ(0) !important;
      -webkit-box-shadow: 0 0 1rem 0 rgba(0,0,0,0.15);
      -moz-box-shadow: 0 0 1rem 0 rgba(0,0,0,0.15);
      box-shadow: 0 0 1rem 0 rgba(0,0,0,0.15);  
    }

    .tablet-nav-wrap {
      min-height: 100%;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      .flex-column {
        flex-basis: 33.33%;
        width: 100% !important;
        flex-grow: 1;
    
        ul {         
          li {
            display: inline-block;
            float:left;
            margin: 0;
          }
        }

        #nav-close {
          position: absolute;
          z-index: 100;
          top: 1rem;
          right: 1rem;
          font-size: 2rem;
          color: #fff;
          cursor: pointer;
          transition: all .2s linear;

          &:hover {
            color: #ddd;
          }
        }
      }

      #tablet-main-nav {
        ul {
          li {
            width: 100%;
            margin: 0;
            a {
              display: block;
              position: relative;
              font-size: $h2-font-size;
              padding: .5rem 0 0 .25rem;
              color: rgba(255, 255, 255, 1);
              transition: all .2s linear;

              &:hover, &.current {
                padding: .5rem 0 0 2.75rem;
                &::before {
                  position: absolute;
                  left: 0;
                  margin: .375rem 0;
                  font-family: Phosphor;
                  font-size: 2rem;
                  content: '\e9dc';
                }
              }

              &.current {
                color: rgba(255, 246, 199, 1);
              }
            } 
  
            &.has-children {  
              a {
                padding-right: 0;
                position: relative;
  
                &::after {
                  position: absolute;
                  top: calc(50% - 1rem);
                  right: 0;
                  margin-left: 5px;
                  font-family: Phosphor;
                  font-size: 2rem;
                  content: "\f1d4";
                  transition: all .1s linear;
                }
  
                &:hover {
                  padding: .5rem 0 0 .25rem;

                  &::before {
                    content: '';
                  }
                }

                &:not(.collapsed) {
                  &::after {
                    top: calc(50% - 1rem);
                    transform: rotate(45deg);
                  }                  
                }
              }

              ul { 
                   
                li {
                  margin: 0;
                  float: none;
    
                  a {
                    display: block;
                    min-width: 100%;
                    font-size: $h3-font-size;

                    &:hover, &.current {
                      padding: .5rem 0 0 2.75rem;
                      &::before {
                        position: absolute;
                        left: 0;
                        margin: auto 0;
                        font-family: Phosphor;
                        font-size: 2rem;
                        content: '\e9dc';
                      }
                    }

                    &:after {
                      content: "";
                    }
                  }

                  &:last-child {
                    margin-bottom: 2rem;
                  }
                }
              } 
            }     
          }
        }
      }

      #tablet-nav-social {
        a {
          margin: .2rem .4rem .2rem .2rem;
  
          &::after {
            border-top: 0;
          }
          
          img {
            width: 2rem;
            height: 2rem;
  
            &:hover {
              opacity: .75;
            }
          }
  
        }
      }
    }
  }

  /* ------------ Main, content styles ------------ */
  &.alert_msg main {
    padding-top: 112px;

    @media only screen and (max-width:959px) {
      padding-top:112px;      
    }

    @media only screen and (max-width:767px) {
      padding-top:108px;      
    }

    @media only screen and (max-width:479px) {
      padding-top:91px;      
    }

    .slider.home-slider {
      .slide {
        height: calc(100vh - 7rem);
      }
    } 
  }
  
  main {
    padding-top: 72px;

    @media only screen and (max-width:959px) {
      padding-top:64px;      
    }

    @media only screen and (max-width:767px) {
      padding-top:57px;      
    }

    @media only screen and (max-width:479px) {
      padding-top:59px;      
    }

    section#artists, section#participate {
      .col-md-6 {
        margin-bottom:30px;
      }
    }

    ul {
      padding: 0 0 0 1.25rem;
      list-style: none;

      li {
        &:before {
          content: "•";
          display: inline-block; 
          width: 1em;
          margin-left: -1em;
        }
      }
    }

    p+ul {
      margin-top: -1rem;
    }

    h3+p {
      margin-top: 1rem;
    }

    ul.download-linkliste {
      list-style: none;
      margin: 30px 0 0;
      padding: 0;

      li {
        margin: 0;
        
        &:before {
          content: "";
          display: inline-block; 
          width: 0;
          margin-left: 0;
        }
      }
    }

    #programme-exhibition {
      ul.download-linkliste {
        margin-top:0; 

        li {
          margin-bottom:.25rem;
        }
      }
    }

    section {
      a {
        border-bottom: 1px solid #333;
        color: #333;

        &.text-danger {
          color: $color-red;
          border-bottom: 1px solid $color-red;
        }

        &:hover {
          text-decoration: none;
          border-bottom: 1px solid #000;
          color: #000;
        }
      }

      .section-header {
        a {
          border-bottom: 0;
        }
      }

      .events-header {
        display: flex;
        align-items: bottom;

        #event-dates {
          float: left;
          padding: .5rem;
          width: 16.5 rem;
          margin: 0 .125rem;
          background-color: #fff;
          border-bottom: 1px solid #000;
          text-align: center;
          font-size: 1.25rem;
          line-height: 1;
        }
      
        #event-nav-prev, #event-nav-next {
          font-size: 2rem;
          float: left;
          color: #999;

          &:hover {
            color: #000;
          }
        }

        .tag-pill {
          display: inline-block;
          position: relative;
          padding: .125rem .5rem;
          margin: .375rem .25rem .275rem 0;
          line-height: 1;
          text-align: center;
          white-space: nowrap;
          vertical-align: baseline;
          border: 2px solid #999;
          font-size: .875rem;
          height: 1.5rem;
          border-radius: .75rem;
          background-color: transparent;

          &:focus {
            outline: none;
          }

          &.cat {
            padding-left: 1.75rem;

            &::before {
              content: '';
              position: absolute;
              top: .25rem;
              left: .375rem;
              display: block;
              width: .75rem;
              height: .75rem;
              border-radius: 50%;
            }
          }

          &.erw::before {
            background-color: $color-erw;
          }

          &.kind::before {
            background-color: $color-kind;
          }

          &.senior::before {
            background-color: $color-senior;
          }

          &.schule::before {
            background-color: $color-schule;
          }

          &:hover, &.active {
            border: 2px solid #000;
            background-color: inherit;
          }
        }
      }

      .embed-responsive-1by1, .embed-responsive-4by3 {
        background-size: cover;
        background-position: center center;
        display: flex;
        border: 0;
        transition: all .3s ease-in-out;

        &:hover {
          background-color: rgba(0, 0, 252, .5);
          transition: all .3s ease-in-out;
          border: 0;
        }
      }

      &:last-of-type {
        padding-bottom: 4rem;
      }
    }

    #current-exhibition {
      .embed-responsive-1by1 {
        
      }
    }

    #social-bar {
      position: absolute;
      z-index: 10;
      top: calc(50vh - 5rem);
      right: 1.25rem;
      width: 2.5rem;
      height: auto;
      
      a {
        width:2.1rem;
        height: 2.1rem;
        border-radius: 50%;
        margin: .2rem;
        background-color: rgba(255, 255, 255, 1);

        img {
          padding: 0;
          width: 100%;
          height: 100%;
        }

        &:hover {
          background-color: rgba(255, 255, 255, .5);
          box-shadow: 0 0 .75rem .375rem rgba(0,0,0,0.15);
        }
      }
    }

    #date-button {
      position: absolute;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-content: center;
      z-index: 10;
      top: 15rem;
      left: calc(50vw - 5rem);
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      text-align: center;
      background-color: rgba(255, 255, 255, .75);
      border: none;
      transition: all .3s ease-out; 

      .date {
        font-size: 1rem;
      }

      &:hover { 
        z-index: 12;
        width: 24rem;
        height: 24rem;
        left: calc(50vw - 12rem);
        top: 6rem;
        background: url("../img/train-map.svg") 0 0 no-repeat;
        background-size: cover;
        transition: all .3s ease-out;

        .date {
          opacity: 0;
        }
      }
    }

    .date-time {
      span {
        display: inline;

        &:before {
          content: " | ";
        } 
      }

      @media (min-width:600px) and (max-width:1279px) {
        span {
          display: block;
  
          &:before {
            content: "";
          } 
        }
      }
    }

    .date-circle {
      position: relative;
      width: 9rem !important;
      height: 9rem !important;
      border-radius: 100%;
      border: 3px solid rgba(0, 0, 0, .15);
      text-align: center;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-content: center;

      small {
        display:block;
        text-transform: uppercase;
        width: 100%;
        line-height: 1;
      }
    }

    .slider {
      width: 100%;

      .slick-next, .slick-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        top: inherit;
        bottom: 1rem;
        right: 1.5rem !important;
        left: inherit;
        height: 2.25rem;
        width: 2.25rem;
        text-align: center;
        border-radius: 50%;
        background: #fff; 
        opacity: 1;

        &:before {
          font-family: Phosphor !important;
          font-size: 2rem;
          line-height: 1;
          color: #000;
          padding: 0;         
        }

        &:hover {
          box-shadow: 0 0 .75rem .375rem rgba(0,0,0,0.25);
        }
      }

      @media (max-width:767px) {
        .slick-next, .slick-prev {
          bottom: -2.125rem;
          box-shadow: 0 0 .75rem .375rem rgba(0,0,0,0.25);
        }
      }

      .slick-prev {
        right: 4.25rem !important;

        &:before {
          content: '\e9dc' !important;
        }
      }

      .slick-next  {
        &:before {
          content: '\ea09' !important;
        }
      }

      &.home-slider {
        .slide {
          height: calc(100vh - 4rem);
          //height: calc(100vh - 7rem);
          color: rgb(0,0,0);
          position: relative;
          display: flex;

          a {
            border: 0 !important;
          }

          .slide-inner {
            line-height: 1.6; 
            padding: .125rem 0; 
            width: 100%;
            margin: 0 0 0 1.5rem;

            .h1, .h2 {
              background-color: #fff;
              display: inline;
              line-height: 1.25; 
              padding: 0 .5rem;
              
              /* Needs prefixing */
              box-decoration-break: clone;
              -webkit-box-decoration-break: clone;
            }

            .h1 {
              font-weight: 400;
            }

            .h2 {
              font-size: 1.25rem;
              padding: .25rem .5rem;
            }
          }

          .slide-img {
            display: flex;
            margin-left: auto;
            margin-right: auto;
            
            img {
              margin: auto;
              align-self: center;           
              width:auto;
              height:auto;
              max-height: 90%;
              max-width: 90%;
              object-fit: contain;
            }
          }

          .slide-date {
            position: absolute;
            z-index: 3;
            align-self: flex-start;
            top: 1.5rem;
            width: 80%;
          }

          .slide-title {
            position: absolute;
            z-index: 3;
            align-self: flex-end;
            bottom: 2.25rem;
            width: 80%;
          }
        }

        @media (max-width:767px) {
          .slide {
            height: 41rem;

            .slide-inner {
              margin: 0 0 0 1rem;
            }

            .slide-date {
              top: 1rem;
            }
  
            .slide-title {
              bottom: 1rem;
              width: 95%;
            }
          }
        }

        @media (max-width:374px) {
          .slide {
            height:38rem;
          }
        }
      }

      &.event-slider {
        .slick-slide {
          margin: 0 .5rem;
        }

        .slick-track {
          margin-left: 0 !important;
        }

        .slick-list {
          margin: 0 -.5rem;
        }

        .slide {
          height: 28rem;
          padding: 1rem;
          position: relative;
          display: flex;
          background-color: #fff;

          .event-slider-content {
            position: absolute;
            display: flex;
            flex-flow: column;
            z-index: 3;
            align-self: center;
            top: 45%;
            width: 95%;
            height: 14rem;
            overflow: auto;

            div.h3 {
              hyphens: auto; 
              -webkit-hyphens: auto;
              -ms-hyphens: auto;
            }

            .btn-div {
              margin-top: auto;
            }
          }
        }

        &.exhibition {
          .slide {
            height: auto;
            padding: 0;

            .textbox {
              min-height: 20rem;
            }
          }
        }
      }

      &.hero-slider {
        .slide {
          height: calc(100vh - 10rem);
          max-height: 42rem; 
          position: relative;
          display: flex;
          background-size: cover !important;
          background-position: center center !important;

          .hero-caption {
            position: absolute;
            bottom: 1rem;
            left: 2rem;
            font-size: .875rem;
            color: #fff;
            text-shadow: 1px 1px 2px #000;
          }

          .hero-slider-content {
            z-index: 3;
            align-self: center;
            justify-self: center;
            width: 100%;
            margin: 2rem;

            .slide-inner {
              line-height: 1.6; 
              padding: .125rem 0; 
              max-width: 40rem;
              margin: 0;
  
              .h1, .h2 {
                background-color: #fff;
                display: inline;
                line-height: 1.25; 
                padding: 0 .5rem;
                
                /* Needs prefixing */
                box-decoration-break: clone;
                -webkit-box-decoration-break: clone;
              }
  
              .h2 {
                font-size: 1.25rem;
                padding: .25rem .5rem;
              }
            }
          }
          @media only screen and (max-width:767px) {
            .hero-slider-content {
              margin: 1rem;
            }
          }          
        }
      }

      &.gallery-slider {
        .slick-next, .slick-prev {
          bottom: 6.5rem;
        }

        .slick-track {
          display: flex;
          align-items: flex-end;
        }

        @media only screen and (max-width:767px) {
          .slick-track {
            align-items: flex-start;
          }
        }

        .slick-slide {
          margin: 0 .5rem;
        }

        .slick-list {
          margin: 0 -.5rem;
        }

        .slide {
          position: relative;
          padding-bottom: 4rem;
          
          img {
            &.landscape {
              max-width: 35rem;
              max-height: none;
              margin-bottom: .5rem;
            }

            &.portrait {
              max-height: 35rem;
              max-width: none;
              margin-bottom: .5rem;
            }
          }

          figcaption {
            position: absolute;
            left: 1rem;
            font-size: .875rem;
          }
        }

        &.bw {
          .slide {
            img {
              filter: grayscale(100%);
            }
          }
        }
      }
    }

    #mfrm {
      select option { color:#000; }
      select.grau { color:#999; }
      select.fehler, select.fehler option:first-child, label.fehler, span.fehler, strong.fehler {
        color: $color-red-light;
      }
      &.antragfrm {
        span.fehler { display:inline-block; margin-bottom:0; }
        .clearbr { clear:left; height:1px; }
      }
      span.fehler {
        display:block;
      }
      #firma { display:none; visibility:hidden; }
    }

    .content-section {
      padding: 3.75rem 0 2.5rem;

      ul {
        list-style: none;

        li {
          &:before {
            content: "•";
            display: inline-block; 
            width: 1em;
            margin-left: -1em;
          }
        }
      }

      &.anker-section {
        padding: 5rem 0 0;

        & + .content-section {
          padding-top: 1.25rem;
        }
      }

      &:first-of-type {
        padding-top: 2.5rem;     
      }

      @media (max-width:767px) {
        &:first-of-type {
          padding-top: 3.5rem;
        }
      }  

      &:last-of-type {
        min-height: calc(100vh - 30rem);
      }

      .h4 {
        margin: 0 0 2rem 0;
      }

      ul { 
        font-size: $base-font-size;
        padding-left: 1.3em;
        margin: 0 0 1.25rem;

        li {
          margin-bottom: .625rem;
        }
      }

      .box-linie {
        margin-bottom: 1.5rem;
        padding-left: 1rem;
        border-left: 2px solid #333;
      }

      form {
        input, select, textarea {
          border: 0;
          border-radius: 0;
          box-shadow: none;
          border-bottom: 1px solid #ccc;
          padding: .75rem .5rem !important;
          min-height: 3rem;
          margin: .5rem 0;
        }

        textarea {
          min-height: 120px;
        }

        select.custom-select {
          background: url(/img/select_arrow.svg) 98% center no-repeat;
          background-color: #fff;
        }

        /* ------------ Forms ------------ */
        .form-control {
          color: #343638; 

          &::placeholder {
            color: #343638;
            opacity: 0; 
          }
        }

        .form-control:focus, .custom-select:focus {
          color: $color-text-dark;
          background-color: #fff;
          border: 0 !important;
          border-bottom: 2px solid #000 !important;
          outline: 0;
          box-shadow: none;
          padding-bottom:12px !important;
        }
        textarea.form-control:focus {
          padding: 6px 5px !important;
        }

        .form-check {
          padding-left: 0;

          .form-check-input {
            -webkit-appearance: none;
            display:none;
          }

          .form-check-input + label::before {
            width: 14px;
            height: 14px;
            border-radius:1px;
            border: 1px solid #333;
            background-color: #fff;
            display: block;
            content: "";
            float: left;
            margin-top:1px;
            margin-right: 9px;
            margin-bottom: 6px;
          }

          .form-check-input:checked + label::before {
            box-shadow: inset 0 0 0 2px #fff;
            background-color: #000;
          }

          label {
            font-size: 1rem;
            color: $color-text-dark;
          }
        }


        .form-group {
          position: relative;
          border-bottom: 0;
          margin-bottom: 1.25rem;
          label {
            position: absolute;
            top: 45%;
            left: .75rem;
            transform: translate3d(0, -50%, 0);
            transition: all .3s;
            padding: .5rem 0 0;
            font-size: 85%;
            color: #666;
          }

          label.txtarea {
            top:24px;
          }
          label.top0 { top: -.75rem; }
        
          input:not(:placeholder-shown) + label,
          input.nep + label,
          input:focus + label,
          textarea.txtarea:not(:placeholder-shown) + label,
          textarea.nep + label,
          textarea.txtarea:focus + label{
            top: -.75rem;
          }
        }


        .description {
          font-size: 13px;
          line-height: 1.3;
        }

        .btn-red-border {
          padding: 12px 25px !important;
          font-size: 14px;
          background-color: #fff;
          color: #000;
          border: 2px solid #000;
          border-radius: 3px;
          transition: all .3s linear;
          text-transform: uppercase;
          letter-spacing: 1px;
          line-height: 12px;

          &:hover {
            background-color: #000;
            color: #fff;
          }
        }
      }

      a {
        &.ico-link {
          display: flex;
          margin-bottom: 12px;

          i {
            margin-right: 12px;
            font-size: 21px;
          }

          span {
            display: inline-flex;
            width: auto;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }

      .wysiwyg {
        .card {
          margin-bottom: 2rem;

          .card-text {
            min-height: 3rem;
          }
        }
      }
    }

    hr {
      background-color: $color-light-grey;
    }

    .side hr:first-child {
      margin-top: 0;
    }

    .label {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 600;
    }

    /* ------------ Accordeon ------------ */
    .acc.frmt {
      border-bottom: 0;
    }

    .acc {
      border-bottom: 1px solid #000;
      margin-top: 1rem;

      .card {
        border: 0;
        border-top: 1px solid #000;
        border-radius: 0;
        background-color: transparent;

        .card-header {
          margin: 0;
          padding: 0;
          background: none;
          border-radius: 0;
          border: 0;

          .card-title {
            a {
              position: relative;
              display: block;
              width: 100%;
              text-align: left;
              color: $color-mid-grey;
              text-decoration: none;
              padding: 1rem 0;
              margin: 0;
              border-bottom: 0;
              outline: none !important;
              
              &::after {
                position: absolute;
                right: .125rem;
                top: calc(50% - 1.25rem);
                font-family: "Phosphor";
                font-size: 2rem;
                content: "\f1d4";
                transition: all .1s linear;
              }

              &.collapsed {
                border-bottom: 0;
              }

              &:not(.collapsed) {
                border-bottom: 0;

                &::after {
                  top: calc(50% - 1.25rem);
                  transform: rotate(-45deg);
                }
              }

              &:focus {
                box-shadow: none;
              }

              .dot {
                display: block;
                float: right;
                margin-top: .75rem;
              }

              @media only screen and (max-width:480px) {
                &.pr-50 {
                  padding-right: 2.5rem !important;
                }
              } 
            }
          }
        }

        .card-body {
          padding: 0 0 1rem; 
          margin: 0;

          &.no-gutters div {
            padding-right: 1rem;
          }

          ul {
            list-style: none;
    
            li {
              &:before {
                content: "•";
                display: inline-block; 
                width: 1em;
                margin-left: -1em;
              }
            }
          }

          .carditem .textbox {
            min-height:20rem;
            div {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  #accordion-cal.acc {
    border-top: 0;
    border-bottom: 0;

    .card {
      border-top: 0;

      .card-header {
        .card-title {
          a {
            min-height: 9rem;

            &::after {
              top: .5rem;
            }

            &.collapsed {
              border-bottom: 1px solid #000;
            }

            &:not(.collapsed) {
              border-bottom: 1px solid transparent;
            } 
  
            .dot {
              display: block;
              float: right;
              margin-top: .25rem;
            }             
          }

          @media only screen and (max-width:480px) {
            .pr-50  {
              padding-right: 2.5rem !important;
            }
          } 
        }
      }

      .card-end-line {
        border-bottom: 1px solid #000;
      }
    }
  }

  #accordion.acc {
    .card {
      .card-header {
        .card-t
      
      .dot {
        display: inline-block;
      }  itle {
          .dot {
            float: right;
            margin-top: .55rem;
          } 
        }
      }
    }
  }

  /* ------------ Accept Cookies ----------- */
  #cookie-bar {
    position: fixed; 
    z-index: 190; 
    right: 0; 
    bottom: 0; 
    left: 0; 
    padding: 1.5rem 0;
    background-color: #222;

    small, .h3, a {
      color: #fff;
    }

    .btn-outline-danger {
      border-color: #fff;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }

    a:not(.btn) {
      text-decoration: none;
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }

  /* ------------ Footer ------------ */
  footer {
    padding: 3.5rem 0 2rem;
    background-color: #555; 
    color: #fff;

    .w-95 { 
      display:inline-block; 
      width: 5.75rem; 
    }

    #footer-nav {
      ul {
        padding: 0;
        li {
          display: block;
          font-size: $h3-font-size;
        }
      }
    }

    a {
      position: relative;
      text-decoration: none;
      line-height: 1.3;
      color: #fff;
      
      &:after {
        border-top: .1em solid #fff;
        content: "";
        position: absolute;
        right: 100%;
        bottom: 0;
        left: 0;
        transition: right .4s cubic-bezier(0,.5,0,1);
      }
      
      &:hover {
        text-decoration: none;
        color: #fff;

        &:after {
          right: 0;
        }
      }
    }
    #footer-social {
      a {
        margin: .2rem;

        &::after {
          border-top: 0;
        }
        
        img {
          width: 2rem;
          height: 2rem;

          &:hover {
            opacity: .75;
          }
        }

      }
    }
  }

  /* ------------ diverse Elements ------------ */
  #dark-overlay {
    position: fixed;
    display:none;
    z-index: 50;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
  }

  #back-to-top {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 2rem;
    right: 1.5rem;
    z-index: 9998;
    width: 2.25rem;
    height: 2.25rem;
    color: #000;
    background: #fff;
    border-radius: 50%;
    line-height: 1;
    font-size: 2rem;
    cursor:pointer;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  }
}
@keyframes fadeInOpacity {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* ------ Photoswipe ------*/

.pswp {
  z-index:9999;
}

.pswp__bg {
  background-color: $bg-grey !important;
}

.pswp__caption {
  background-color: transparent !important;
  //display: none;
}

.pswp__caption__center {
  margin: 0 1rem .5rem;
  color: $color-text !important;
  font-size: 1rem !important;
  letter-spacing: .025rem;
  max-width: 100%;
  
  .fliess {
    display: none;
  }
}

.pswp__top-bar {
  background-color: transparent !important;
}

.pswp__button.pswp__button--share {
  display: none !important;
}

.pswp__button--arrow--left::before, 
.pswp__button--arrow--right::before {
  background-color:transparent !important;
}


/* Media queries */

@media only screen and (min-width:1377px) {
  body {
    header {
      .breadcrumb {
          a.current, span, span.l2, span.slash { display:inline-block; }
      }
    }
  }
}

@media only screen and (min-width:1024px) {

}

@media only screen and (max-width:1023px) {
  body {
    header {
      #logo {
        border-bottom: 2px solid #000;
      }
        
      .breadcrumb, .menu-text, #logo {
        font-size: 2rem;
      }

      .menuwrap {  
        #hamburger {
          margin-top: -2px;

          .menu-text {
            margin: -7px 0 0;
          }
          
          .menu-icon {
            span {
              width: 34px;
              height: 2px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    .col-md-3, .col-md-4, .col-md-6 {
      margin-bottom: 2.5rem;
    }

    main {
      
      #social-bar {
        display: none !important;
      }

      .date-section {
        position: relative;
        display:block;
        width: 100%;
        height: 18rem;
        background-color: #f1f2f4;

        #date-button {
          top: 2.5rem;
          left: calc(50vw - 7rem);
          width: 14rem;
          height: 14rem;
        }
      }

      .slider {
        &.hero-slider {
          .slide {
            height: 30rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width:476px) and (max-width: 1219px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width:767px) {
  body {
    header {
      #logo {
        border-bottom: 2px solid #000;
      }
        
      .breadcrumb, .menu-text, #logo {
        font-size: 1.75rem;
        a {
          border-bottom: 2px solid transparent;

          &.current, &:hover {
            border-bottom: 2px solid #ccc;
          }
        }
        span {
          border-bottom: 2px solid #ccc;
        }
        span.slash { border-bottom:0; }
      }

      .menuwrap { 
        margin-right: 1rem;

        #hamburger {          
          .menu-icon {
            span {
              width: 30px;
              height: 2px;
              margin-bottom: 6px;
            }
          }
        }
      }
    }
    main {
      .acc {
        .card {
          .card-body {
            padding: 0; 
            margin: 0;
            
            &.no-gutters div {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width:480px) and (max-width: 1025px) {
  body {
    main {
      .slider.home-slider .slide {
        max-height: 40rem;
      }
    }
  } 
}

@media (max-width:520px) {
  body {

    #intro {
      .py-80 {
        padding-bottom: 40px !important;
      }

      .gallery-slider.pb-40 {
        padding-bottom: 0 !important;
      }
    }

    .newsletter-section {
      .py-70 {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
    }

    h1, .h1 {
      font-size: 2.5rem;

      &.my-50 {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
      }
    }
    h3, .h3 {
      font-size: 1.5rem;
    }
    header {
      #top-banner {
        a span {
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    aside#tablet-nav {
      width: calc(100vw - 2rem);
    }
    main {
      .slider {
        &.event-slider {
          .slide {
            max-height: 30rem;
          }
        }
        &.exhibition {
          .slide {
            max-height: inherit;
          }
        }
      }
      .date-circle {
        width: 8rem;
        height: 8rem;
        margin: 0 auto;
      }
    }
  }

  #current-exhibition {
    .row {
      margin-bottom: 2.5rem !important;

      .col-md-6:nth-child(1n) {
        margin-bottom: 0 !important;
      }
    }
  }
}



