/* Font sizes */
$base-font-size: 1.25rem;
$small-font-size: 1.0625rem;
$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 1.825rem; 
$h4-font-size: 1.5rem;
$h5-font-size: 1.125rem;

/* Redaktion */
$font-size-seventeen:   1.0625rem; // 17px
$font-size-mainnav:     1.3125rem; // 21px

$font-sizes: (
    html: ( xs: .75rem, sm: .8rem, md: .9rem, lg: 1rem),
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $values in $font-sizes {
      @each $n, $value in $values {
        @if($infix == "-#{$n}" or ($infix == "" and $n == 'xs')) {
          #{$name} { font-size: $value; }
        }
      }
    }
  }
}

/* Background colors */
$bg-rose:       #fbece5;
$bg-elder:      #D6DAEB;
$bg-grey:       #f1f2f4;
$bg-bluegrey:   #D8DFE3;

/* Group colors */
$color-erw:     #77B9F2;
$color-fam:     #9fd28a;
$color-kind:    #EFDB6E;
$color-senior:  #C8A489;
$color-schule:  #ABDBDB;


/* Header border */
$header-border: 2px solid #000;